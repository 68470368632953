import React, { useEffect } from 'react'
import './App.css';
import Home from './components/Home/Home'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer';

const App = () => {
  return (
    <div>
          <div class="door-container">
        <div class="left-door"></div>
        <div class="right-door"></div>
    </div>
      <Navbar />
      <Home />
      <Footer />
      <>
        <div class="border left-border"></div>
        <div class="border right-border"></div>
      </>
    </div>
  )
}

export default App