import React from "react";
import "./Navbar.css";
import logo from "./../assets/logo.png";
import talkana from "./../assets/talkana.png";
import { Link } from "react-scroll";
import log from "./../assets/1_preview_rev_2.png";

const Navbar = () => {
  return (
    <div className="Navbar">
      <Link
        style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        to="home"
        span="true"
        smooth="true"
      >
        <img className="logo-img" src={log} alt="" />
      </Link>

      <ul className="ul-nav">
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          activeClass="active"
          to="home"
          span="true"
          smooth="true"
        >
          <li>الرئيسية</li>
        </Link>

        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to="desktop-applications"
          span="true"
          smooth="true"
        >
          <li>غرف الدردشة</li>
        </Link>

        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to="Advantages"
          span="true"
          smooth="true"
        >
          <li>المميزات</li>
        </Link>

        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to="Download-app"
          span="true"
          smooth="true"
        >
          <li>تحميل البرنامج</li>
        </Link>
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to="Contact"
          span="true"
          smooth="true"
        >
          <li>الاتصال بنا</li>
        </Link>
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to="Terms"
          span="true"
          smooth="true"
        >
          <li>الشروط والقوانيين</li>
        </Link>
      </ul>

      <Link
        style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        to="home"
        span="true"
        smooth="true"
      >
        <img className="img-logo-right" src={talkana} alt="" />
      </Link>
    </div>
  );
};

export default Navbar;
