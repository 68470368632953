import React, { useState } from "react";
import "./Home.css";
import industry from "./../assets/industry.jpg";
import astronaut from "./../assets/astronaut.mp4";
import facebook from "./../assets/facebook.png";
import whatsapp from "./../assets/whatsapp.png";
import Telegram from "./../assets/Telegram.webp";
import menu from "./../assets/menu-room.png";
import room from "./../assets/room.png";
import apk from "./../assets/apkd.png";
import preview from "./../assets/preview.png";
import win from "./../assets/win.png";
import gmail from "./../assets/gmail.png";
import Link2 from "react-scroll/build/npm/modules/components/Link";
import { Link } from "react-router-dom";
import d1 from "./../assets/d1.png";
import d2 from "./../assets/d2.png";
import d3 from "./../assets/d3.png";
import d4 from "./../assets/d4.png";

const Home = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [urlImg, setUrlImg] = useState(null);

  const handleImageClick = (img) => {
    setUrlImg(img);
    setIsZoomed(!isZoomed);
  };

  return (
    <div className="Home">
      <div className="introduction" id="home">
        <video
          src={astronaut}
          autoPlay
          muted
          loop
          controls={false}
          width="100%"
        />
        <div>
          <div className="text-overlay">
            <div className="details-app">
              <h1>لأيك جي شات انضم للدردشة</h1>
              <h3>إبدأ دردشة فيديو أو دردشة صوتية أو دردشة كتابية </h3>
              <h3> مع أكبر تجمع للدردشات الصوتية</h3>
            </div>
            <Link2
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
              to="Download-app"
              span="true"
              smooth={true}
            >
              <button className="btn-download">تحميل لأيك جي</button>
            </Link2>
          </div>
        </div>
      </div>

      <div className="explanation">
        <div className="section-right">
          <h3>تواصل مع اصدقائك</h3>
          <p>
            ابدأ رحلة جديدة في اكتشاف أصدقاء جدد مع تطبيق لأيك جي شات هو تطبيق
            للمحادثات الجماعية والخاصة تمكنك من إجراء محادثات آمنة وأكثر خصوصية
            محمي من كل عمليات الاختراق. كفك الباند. أو الفلو. لأيك جي شات يعمل
            على كل أجهزة الكمبيوتر التي تستخدم أي نوع من أنظمة ويندوز وعلى كل
            أنواع الهواتف المحمولة العاملة بنظام أندرويد وهواوي (إيفون Ios).
            يمكن عن طريق برنامج الدردشة المقدم من لأيك جي شات استخدام تقنية
            الصوت والصورة والتواصل مع الأهل والأصدقاء والتواصل مع من ترغب في أي
            وقت ومن أي مكان ولا يتطلب سرعة أنترنت عالية حيث يعتبر برنامجنا آمن
            وسهل الاستخدام ويحفظ الخصوصية الكاملة لكل المستخدمين بمختلف فئاتهم
            سهولة الاتصال من كل دول العالم من مزودات خدمة الأنترنت في العالم
            بالسرعة المطلوبة لدخول الدردشة واستقبال صوت بشكل جيد هي 1.1
            كيلوبايت. حفاظ كامل على سرية وخصوصية المستخدم إمكانية الاشتراك باسم
            مسجل محمي في كامل البرنامج ضمن كل الغرف وبكل المواقع.
          </p>
          <Link
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
              color: "black",
              cursor: "pointer"
            }}
            to="Download-app"
            span="true"
            smooth={true}
          >
            <button className="btn-downl-expl">تحميل البرنامج</button>
          </Link>
        </div>
        <img className="img-explanation" src={preview} alt="error img" />
      </div>

      <div id="desktop-applications">
        <div id="">
          <div className="DesktopApplications">
            <div className="det-desc">
              <h2>تطبيق سطح المكتب</h2>
              <p>
                تتوفر أيضا نسخه خاصة بتطبيق لأيك جي للدردشات الصوتية على سطح
                المكتب خاصة بمستخدمي الويندوز
              </p>
            </div>

            <div className="clone-room">
              <div
                className={`card-clo-room ${isZoomed ? "zoomed" : ""}`}
                onClick={() => handleImageClick(d1)}
              >
                <img
                  className={`img-room ${isZoomed ? "zoomed" : ""}`}
                  src={d1}
                  alt=""
                />
                <h3> قائمة الغرف والدول</h3>
              </div>
              <div
                className={`card-clo-room ${isZoomed ? "zoomed" : ""}`}
                onClick={() => handleImageClick(d2)}
              >
                <img
                  className={`img-room ${isZoomed ? "zoomed" : ""}`}
                  src={d2}
                  alt=""
                />
                <h3>دخول الغرف</h3>
              </div>
              <div
                className={`card-clo-room ${isZoomed ? "zoomed" : ""}`}
                onClick={() => handleImageClick(d3)}
              >
                <img
                  className={`img-room ${isZoomed ? "zoomed" : ""}`}
                  src={d3}
                  alt=""
                />
                <h3>الدخول الى الاعدادات</h3>
              </div>
              <div
                className={`card-clo-room ${isZoomed ? "zoomed" : ""}`}
                onClick={() => handleImageClick(d4)}
              >
                <img
                  className={`img-room ${isZoomed ? "zoomed" : ""}`}
                  src={d4}
                  alt=""
                />
                <h3>واجهة الملف</h3>
              </div>
            </div>

            {urlImg && (
              <img
                className={`img-room ${isZoomed ? "zoomed" : ""}`}
                src={urlImg}
                alt=""
                onClick={() => handleImageClick("")}
              />
            )}
          </div>
        </div>
      </div>

      <div className="advantages" id="Advantages">
        <img className="img-intro" src={industry} alt="" />
        <div className="container-text-advantages">
          <h2>المميزات</h2>
          <p>يتميز تطبيقنا بسهولة الاستخدام وتجربة افضل بالتواصل</p>
        </div>
      </div>

      <div className="download-app" id="Download-app">
        <div className="icon-app">
          <h2>تحميل لأيك جي</h2>
        </div>

        <div className="all-cards-downl">
          <div className="card-downl">
            <h3>جوجل بلي</h3>

            <div className="section-bott">
              <img
                className="img-downl-app"
                src="https://storage.googleapis.com/gweb-uniblog-publish-prod/images/Logo_Play_512px_clr_nGVTgYY.width-500.format-webp.webp"
                alt="error"
              />
              <h4>Google Play</h4>
              <button>تحميل التطبيق</button>
            </div>
          </div>

          <div className="card-downl">
            <h3>آيفـون</h3>
            <div className="section-bott">
              <img
                className="img-downl-app"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Apple_logo_grey.svg/1724px-Apple_logo_grey.svg.png"
                alt="error"
              />
              <h4>App Store</h4>
              <button>تحميل التطبيق</button>
            </div>
          </div>

          <div className="card-downl">
            <h3>ويندوز</h3>
            <div className="section-bott">
              <img className="img-downl-app" src={win} alt="error" />
              <h4>Windows</h4>
              <button>تحميل التطبيق</button>
            </div>
          </div>

          <div className="card-downl">
            <h3>هواوي</h3>
            <div className="section-bott">
              <img
                className="img-downl-app"
                src="https://seeklogo.com/images/H/huawei-appgallery-logo-A801F4AE11-seeklogo.com.png"
                alt="error"
              />
              <h4>Huawei</h4>
              <button>تحميل التطبيق</button>
            </div>
          </div>

          <div className="card-downl">
            <h3>تحميل برابط مباشر</h3>
            <div className="section-bott">
              <img className="img-downl-app" src={apk} alt="error" />
              <h4>Apk</h4>
              <button>تحميل التطبيق</button>
            </div>
          </div>
        </div>
      </div>

      <div className="contact" id="Contact">
        <h2>الاتصال بنا</h2>

        <div className="grid-contact">
          <div className="card-contact">
            <h3>صفحة الفيس بوك</h3>
            <div className="email-cont">
              <p>لايك جي شات الصوتي</p>
              <Link to="https://www.facebook.com/profile.php?id=100064212338252">
                <img className="ico" src={facebook} alt="" />
              </Link>
            </div>
          </div>

          <div className="card-contact">
            <h3> انتقل مباشر الى واتس اب</h3>
            <div className="email-cont">
              <p>(078000000)</p>
              <Link to="https://wa.me/+96277777">
                <img className="ico" src={whatsapp} alt="" />
              </Link>
            </div>
          </div>

          <div className="card-contact">
            <h3>البريد الالكتروني</h3>
            <div className="email-cont">
              <p>support@laikji.com</p>
              <Link to="">
                <img className="ico" src={gmail} alt="" />
              </Link>
            </div>
          </div>

          <div className="card-contact">
            <h3>تحدث مباشر تليجرام</h3>
            <div className="email-cont">
              <p>talqana</p>
              <Link to="https://t.me/875487579567">
                <img className="ico" src={Telegram} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="terms" id="Terms">
        <h2>شروط وأحكام برنامج لأيك جي شات</h2>
        <div className="room-laws">
          <h4>شروط الدخول لغرف الدردشة في برنامج لأيك جي شات:</h4>
          <div className="laws">
            <h4>
              • دخولك لغرف الدردشة يعني موافقتك لكل مما يلي وعدم الالتزام بها
              سيؤدي لحظرك بشكل كامل من كل الغرف بدون سابق إنذار.
            </h4>
            <h4>
              • على الزائر عدم التلفظ بالكلام البذيء والمشاركة بكل أدب واحترام
              وعدم التطرق إلى الخلافات المذهبية والعقائدية والسياسية التي من
              شأنها إثارة البلبلة وعدم التطرق إلى كلام يسيء إلى الله أو الرسل
              الكرام أو الصحابة الكرام بأي كلام غير لائق، واحترام الآخرين
              والمواضيع المطروحة في كل غرفة.
            </h4>
            <h4>
              • يمنع الدخول بعدة أجهزة بأسماء وهمية لرفع ترتيب الغرفة وهذا يؤدي
              لحظر جميع الأجهزة.
            </h4>
            <h4>
              • يمنع دخول الغرف بطريقة غير شرعية أو الدخول بأجهزة وهمية أو
              استعمال أي برامج تؤذي الغير أو استعمال تطبيقات دردشة وهمية بالتالي
              يؤدي لحرمانك من الدخول بشكل دائم ودون سابق إنذار.
            </h4>
            <h4>
              • يمنع دخول الغرف بأسماء غير لائقة أو غير مناسبة لمكانة البرنامج
              والقائمين عليه.
            </h4>
            <h4>
              • يمنع استخدام الغرف لأمور شخصية لأنها غرف عامة ويحق للجميع
              استخدامها.
            </h4>
            <h4>
              • يمنع وضع الإعلانات داخل الغرفة (مواقع أو روابط أخرى) أو إيميلات
              أو أرقام هاتف أو صور شخصية للغير.
            </h4>
            <h4>
              • يمنع وضع روابط لمواقع إباحية أو روابط لصفحات فيها فضائح وما
              شابه.
            </h4>
            <h4>
              • يمنع تشغيل برنامج الكاميرا للعرض الإباحي أو الإعلانية ويحق لأي
              شخص التبليغ عن أي استخدام مخالف لنظام الكاميرا.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
