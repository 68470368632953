import React from 'react';
import './Footer.css';
import fs from './../assets/fs.jpg'

const Footer = () => {
  return (
    <div className='Footer'>
        <h1>الحقوق محفوظة ملتقي لأيك جي الصوتي</h1>
        <img src={fs} alt='error' />
    </div>
  )
}

export default Footer